<template>
  <div class="page-view">
    <!--    <van-nav-bar title="请假审批管理" safe-area-inset-top />-->
    <!--    <van-nav-bar title="个人请假" safe-area-inset-top />-->
    <div class="header-view">
      <div style="width: 100%;display: flex;justify-content: center;margin-top: 15px">
        <div class="tabs-view">
          <div :class="tabIndex===0?'select':'tab-item-view'" @click="tabIndex=0">
            <span>待审批</span>
          </div>
          <div :class="tabIndex===1?'select':'tab-item-view'" @click="tabIndex=1">
            <span>审批记录</span>
          </div>
        </div>
      </div>
      <div class="filter-view">
        <van-dropdown-menu style="width:200px;height:100%;padding-left: 20px" active-color="#1778FF">
          <van-dropdown-item v-model="filter.yearmonth" :title="filter.yearmonth?'':'年份'" :options="yearColumns" @change="onOptionChange" />
          <van-dropdown-item v-model="filter.attendanceid" :title="filter.attendanceid?'':'请假类型'" :options="option2" @change="onOptionChange" />
        </van-dropdown-menu>
        <van-search v-model="filter.username" placeholder="申请人姓名" background="transparent" shape="round" :clearable="false" @search="onSearch" />
      </div>
    </div>

    <div class="content-view">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-if="list.length||refreshing"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          class="hidden-list-view"
          @load="onLoad"
        >
          <div v-for="(item,index) in list" :key="index" class="hidden-item-view">
            <div class="item-top-view" @click.stop="toDetail(item)">
              <div class="flex-row" style="align-items: center">
                <span class="flex-row van-multi-ellipsis--l2" style="font-size: 17px;color: #03081A;font-weight: 550">{{ item.attendance_name||'-' }}·{{ item.userName||'-' }}</span>
                <div class="tag-view">
                  <img v-if="item.extFiled2==='PC'" src="@/assets/personal/icon_pc.png" alt="app" style="width: 16px;height: 16px">
                  <img v-else src="@/assets/personal/icon_app.png" alt="app" style="width: 16px;height: 16px">
                  <span style="margin-left: 5px">{{ item.extFiled2||'-' }}</span>
                </div>
                <div class="flex-1 " />
                <span class="cell-title-view" style="width: auto">请假天数：</span>
                <span class="cell-value-view" style="font-weight: 550;color: #03081A">{{ item.leaveDay || '-' }}天</span>
              </div>
              <div class="tip-view">
                申请时间：{{ item.leaveDate||'-' }}
              </div>
              <div class="flex-row" style="margin-top:10px;justify-content: space-between;align-items: center">
                <span style="font-size: 15px;color: #03081A">{{ item.leaveStartTime || '-' }}</span>
                <img src="@/assets/personal/icon_arrow.png" alt="arrow" style="width: 64px;height: 6px">
                <span style="font-size: 15px;color: #03081A">{{ item.leaveEndTime || '-' }}</span>
              </div>
              <div class="flex-row" style="margin-top:10px">
                <span class="cell-title-view">部&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;门&nbsp;：</span>
                <span class="cell-value-view">{{ item.deptName || '-' }}</span>
              </div>
              <div class="flex-row" style="margin-top:10px">
                <span class="cell-title-view">当前节点：</span>
                <span class="cell-value-view">{{ item.extFiled3 || '-' }}</span>
              </div>
            </div>
            <div class="item-bottom-view">
              <van-button v-if="!tabIndex" type="primary" size="mini" color="#4B74FF" style="width: 78px;height: 32px" @click="toAudit(item)">审核</van-button>
              <van-button v-else type="default" size="mini" style="width: 78px;height: 32px;background: #F5F6F9;color: #03081A;border: none" @click="toDetail(item)">查阅</van-button>
            </div>
          </div>
        </van-list>
        <van-empty v-else description="暂无数据" />
      </van-pull-refresh>
    </div>
    <van-popup v-model="showAuditPopup" round position="center" :close-on-click-overlay="false" close-on-popstate closeable @close="onPopupClose">
      <span class="popup-title-txt">审核意见</span>
      <div class="flex-column" style="padding:  20px 0;width: 85vw;">
        <van-form ref="popupFormRef" :show-error="false" input-align="left" validate-trigger="onSubmit">
          <van-field
            v-model="optionMap[auditForm.rejected]"
            label="审批意见"
            required
            right-icon="arrow-down"
            :rules="[{ required: true, message: '请选择审批意见' }]"
            @click.stop="showTypePicker=true"
          />
          <van-field
            v-model="auditForm.approvalMsg"
            rows="5"
            type="textarea"
            label="备注"
            maxlength="200"
            placeholder="添加备注"
            required
            :rules="[{ required: true, message: '请添加备注' }, { min: 1, max: 200, message: '长度在 3 到 200 个字符' }]"
          />
          <van-field
            v-if="(!((selectItem.leaveDay <= 1 && selectItem.extFiled4 != null && selectItem.extFiled3 === '主任审核')||( selectItem.extFiled4 != null && selectItem.extFiled3 === '人资主任审核'))&&!auditForm.rejected)"
            v-model="auditForm.approvalPersonName"
            label="审批人"
            right-icon="arrow-down"
            placeholder="请选择"
            required
            :rules="[{ required: true, message: '请选择下一节点审批人' }]"
            @click.stop="selectApprovalPerson"
          />
        </van-form>
        <div class="bottom-btn-view">
          <van-button size="small" type="default" round block @click.stop="showAuditPopup=false">取消</van-button>
          <van-button size="small" type="primary" style="background: #1778FF;border: none" round block @click.stop="handleAudit()">提交</van-button>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="showApprovalPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="approvalPersons"
        value-key="name"
        @confirm="onApprovalConfirm"
        @cancel="showApprovalPicker = false"
      />
    </van-popup>
    <van-popup v-model="showTypePicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="options"
        value-key="label"
        @confirm="onTypeConfirm"
        @cancel="showTypePicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { postRequest } from '@/utils/api'
import { removeToken } from '@/utils/auth'

export default {
  name: 'Personal',
  data() {
    return {
      list: [],
      showAuditPopup: false,
      filter: {
        yearmonth: this.$dayjs().year().toString(),
        attendanceid: '',
        username: ''
      },
      option1: [],
      option2: [],
      refreshing: false,
      loading: false,
      finished: false,
      pageNo: 1,
      pageCount: 1,
      init: true,
      tabIndex: 0,
      auditForm: {
        approvalMsg: '同意',
        approvalPerson: '',
        approvalPersonName: '',
        rejected: 0
      },
      selectItem: {},
      approvalPersons: [],
      showApprovalPicker: false,
      showTypePicker: false,
      optionMap: {
        1: '驳回',
        0: '通过'
      },
      isSend: 1,
      options: [{ label: '通过', value: 0 }, { label: '驳回', value: 1 }]
    }
  },
  computed: {
    userId() {
      return this.$store.getters.userId
    },
    userName() {
      return this.$store.getters.userName
    },
    userInfo() {
      return this.$store.getters.userInfo
    },
    yearColumns() {
      const currentYear = this.$dayjs().year()
      console.log('yearList===', currentYear)
      const yearList = []
      for (let index = 0; index < 5; index++) {
        yearList.push({
          text: currentYear - index + '年',
          value: (currentYear - index).toString()
        })
      }
      console.log('yearList===', yearList)
      return yearList
    }
  },
  watch: {
    tabIndex(val) {
      this.filter.username = ''
      this.onRefresh()
    }
  },
  mounted() {
    removeToken('Authorization')
    removeToken('IscUserId')
    this.initData()
  },
  activated() {
    if (this.init) {
      return
    }
    this.onRefresh()
  },
  methods: {
    initData() {
      this.$store.dispatch('user/setUserInfo', {
        appKey: 'ddmpt_zxjc',
        ticket: this.$route.query.ticket
      }).then(() => {
        this.init = false
        this.getAttendanceType()
        this.onLoad()
      })
    },
    onLoad() {
      if (this.refreshing) {
        this.list = []
        this.refreshing = false
      }
      if (this.tabIndex) {
        this.getApprovedList()
        return
      }
      this.getApprovalList()
    },
    onRefresh() {
      this.finished = false
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.pageNo = 1
      this.onLoad()
    },
    // 获取待审核列表
    getApprovalList() {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 30000
      })
      postRequest('/pems/attendance/tAttendanceLeaveDate/getUsetLeaveDataApproval', {
        ...this.filter,
        pageNum: this.pageNo
      }).then(res => {
        this.$toast.clear()
        console.log('res', res)
        this.loading = false
        if (!res.data.successful) {
          this.list = []
          return
        }
        this.pageCount = res.data.resultValue.pages
        const dataList = res.data.resultValue.list
        if (this.pageNo === 1) {
          this.list = dataList
        } else {
          this.list = [...this.list, ...dataList]
        }
        this.pageNo++
        this.finished = this.pageNo > this.pageCount
      }).catch(() => {
        this.$toast.clear()
      })
    },
    // 获取已审核列表
    getApprovedList() {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 30000
      })
      postRequest('/pems/attendance/tAttendanceLeaveDate/getUsetLeaveDataApproved', {
        ...this.filter,
        pageNum: this.pageNo
      }).then(res => {
        console.log('res', res)
        this.$toast.clear()
        this.loading = false
        if (!res.data.successful) {
          this.list = []
          return
        }
        this.pageCount = res.data.resultValue.pages
        const dataList = res.data.resultValue.list
        if (this.pageNo === 1) {
          this.list = dataList
        } else {
          this.list = [...this.list, ...dataList]
        }
        this.pageNo++
        this.finished = this.pageNo > this.pageCount
      }).catch(() => {
        this.$toast.clear()
      })
    },
    // 获取请假类型
    getAttendanceType() {
      postRequest('/pems/attendance/tAttendanceData/findAttendanceType', {}).then(res => {
        if (!res.data.successful) {
          return
        }
        const list = res.data.resultValue || []
        const options = [{ text: '全部', value: '' }]
        this.option2 = [...options, ...list.filter(e => {
          return e.id !== '1' && e.id !== '2' && e.id !== '3' && e.id !== '9' && e.id !== '10'
        }).map(item => {
          return {
            text: item.attendancename,
            value: item.id
          }
        })]
      })
    },
    onOptionChange(val) {
      this.onRefresh()
    },
    onSearch() {
      this.onRefresh()
    },
    toDetail(item) {
      if (!this.tabIndex) {
        this.$router.push({
          name: 'audit/auditFrom',
          query: {
            id: item.id
          }
        })
        return
      }
      this.$router.push({
        name: 'audit/auditDetail',
        query: {
          id: item.id
        }
      })
    },
    toAudit(item) {
      this.selectItem = item
      this.showAuditPopup = true
      this.getApprovalPersons()
    },
    // 获取审批人
    getApprovalPersons() {
      if (this.selectItem.leaveDay > 1 && this.selectItem.extFiled4 != null && this.selectItem.extFiled3 === '主任审核') {
        this.$toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration: 30000
        })
        postRequest('/pems/attendance/tAttendanceLeaveDate/getApprovalPersonsByParam', {
          types: 'RZZY',
          deptid: '68000004'
        }).then(res => {
          this.$toast.clear()
          if (!res.data.successful) {
            return
          }
          this.approvalPersons = res.data.resultValue || []
          if (this.approvalPersons && this.approvalPersons.length) {
            this.auditForm.approvalPerson = this.approvalPersons[0].id
            this.auditForm.approvalPersonName = this.approvalPersons[0].name
          }
        }).catch(() => {
          this.$toast.clear()
        })
        return
      }
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 30000
      })
      postRequest('/pems/attendance/tAttendanceLeaveDate/getApprovalPersons', {}).then(res => {
        this.$toast.clear()
        if (!res.data.successful) {
          return
        }
        this.approvalPersons = res.data.resultValue || []
        if (this.approvalPersons && this.approvalPersons.length) {
          this.auditForm.approvalPerson = this.approvalPersons[0].id
          this.auditForm.approvalPersonName = this.approvalPersons[0].name
        }
      }).catch(() => {
        this.$toast.clear()
      })
    },
    selectApprovalPerson() {
      this.showApprovalPicker = true
    },
    onApprovalConfirm(val) {
      console.log('confirm===', val)
      this.auditForm.approvalPerson = val.id
      this.auditForm.approvalPersonName = val.name
      this.showApprovalPicker = false
    },
    // 审核拒绝
    handleAudit() {
      this.$refs['popupFormRef'].validate().then(() => {
        this.$dialog.confirm({
          title: '提示',
          message: '是否对该请假进行审批',
          confirmButtonColor: '#1778FF',
          confirmButtonText: '是',
          cancelButtonText: '否'
        }).then(() => {
          if (this.auditForm.rejected === 1 ||
           (!((this.selectItem.leaveDay <= 1 && this.selectItem.extFiled4 != null && this.selectItem.extFiled3 === '主任审核') ||
           (this.selectItem.extFiled4 != null && this.selectItem.extFiled3 === '人资主任审核')) && this.auditForm.rejected === 0)) {
            this.$dialog.confirm({
              title: '提示',
              message: '是否发送短信给审批人',
              confirmButtonColor: '#1778FF',
              confirmButtonText: '是',
              cancelButtonText: '否'
            }).then(() => {
              this.isSend = 1
            }).catch(() => {
              this.isSend = 0
            }).finally(() => {
              this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 30000
              })
              postRequest('/pems/attendance/tAttendanceLeaveDate/approvalPersonalLeaveData', {
                ...this.selectItem,
                approvalPerson: this.auditForm.approvalPerson,
                approvalOption: this.auditForm.approvalMsg,
                rejected: this.auditForm.rejected,
                isSend: this.isSend ? 1 : 0
              }).then(res => {
                this.$toast.clear()
                if (!res.data.successful) {
                  return
                }
                this.showAuditPopup = false
                this.$toast.success('审核成功')
                this.onRefresh()
              }).catch(() => {
                this.$toast.clear()
                this.$toast('审核失败')
              })
            })
          } else {
            this.$toast.loading({
              message: '加载中...',
              forbidClick: true,
              duration: 30000
            })
            postRequest('/pems/attendance/tAttendanceLeaveDate/approvalPersonalLeaveData', {
              ...this.selectItem,
              approvalPerson: this.auditForm.approvalPerson,
              approvalOption: this.auditForm.approvalMsg,
              rejected: this.auditForm.rejected,
              isSend: this.isSend ? 1 : 0
            }).then(res => {
              this.$toast.clear()
              if (!res.data.successful) {
                return
              }
              this.showAuditPopup = false
              this.$toast.success('审核成功')
              this.onRefresh()
            }).catch(() => {
              this.$toast.clear()
              this.$toast('审核失败')
            })
          }
        }).catch(() => {
          this.$toast('取消审核')
        })
      }).catch(e => {
        this.$toast.clear()
        console.log(e)
      })
    },
    onPopupClose() {
      this.$refs['popupFormRef'].resetValidation()
    },
    onTypeConfirm(val) {
      console.log('confirm===', val)
      this.auditForm.rejected = val.value
      if (val.value) {
        this.auditForm.approvalMsg = '不同意'
      } else {
        this.auditForm.approvalMsg = '同意'
      }
      this.showTypePicker = false
    }
  }
}
</script>
<style lang="scss" scoped>
.page-view {
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  background-color: #F5F6F9;

  .header-view{
    width: 100%;
    height: 196px;
    background: linear-gradient(to bottom, #3E70FE 0, #F5F6F9 100%);

    .tabs-view{
      display: flex;
      background: #4B74FF;
      height: 36px;
      align-items: center;
      justify-content: center;
      width: 244px;
      border-radius: 20px;

      .tab-item-view{
        display: flex;
        flex-direction: column;
        font-size: 16px;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 32px;
        color: white;
      }

      .select{
        font-weight: bold;
        color: #4B74FF;
        background: white;
        border-radius: 20px;
        width: 120px;
        display: flex;
        flex-direction: column;
        font-size: 16px;
        height: 32px;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .filter-view{
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    width: 100%;
    margin-top: 5px;
    ::v-deep .van-cell{
      line-height: normal;
    }
  }

  .content-view {
    display: flex;
    flex-direction: column;
    height: calc(100% - 48px);
    overflow-y: auto;
    margin-top: -90px;

    .top-content-view {
      width: 100%;
      padding: 16px 12px 12px 12px;
      box-sizing: border-box;
      font-size: 17px;
      background-color: white;
      color: #03081A;
    }

    .tips-view {
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: #FDF6EB;
      color: #9A6938;
      font-size: 12px;
      padding: 12px;
      box-sizing: border-box;
      line-height: 16px;
    }

    .btn-view {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #03081A;
      font-size: 15px;
      border-radius: 8px;
      border: 1px solid #CFD2D9;
      background-color: white;
    }

    .btn-select-red {
      background-color: #F53F3F;
      color: white;
      border: 0;
    }

    .btn-select-green {
      background-color: #007A77;
      color: white;
      border: 0;
    }

    .hidden-list-view {
      display: flex;
      flex-direction: column;
      padding: 12px;

      .hidden-item-view {
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 8px;
        margin-bottom: 8px;
        box-sizing: border-box;
      padding: 16px 12px 10px 12px;

        .item-top-view {
          display: flex;
          flex-direction: column;

          .tip-view{
            background: #FFF7EB;
            height: 24px;
            color: #FFA83F;
            display: flex;
            align-items: center;
            padding-left: 10px;
            margin-top: 10px;
            font-size: 13px;
          }

          .tag-view{
            margin-left: 10px;
            height: 20px;
            border-radius: 10px;
            background: #ECF1FE;
            font-size: 13px;
            color: #4B74FF;
            padding: 0 10px;
            display: flex;
            align-items: center;
          }

          .cell-title-view{
            width: 100px;
            color:#838DA0;
            font-size: 13px;
          }

          .cell-value-view{
            color:#838DA0;
            font-size: 13px;
          }
          .delete-view{
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: #fa3f3f;
            color: white;
            font-weight: bold;
            margin-top: 2px;
            font-size: 20px;
          }
        }

        .item-bottom-view {
          display: flex;
          align-items: center;
          padding-left: 12px;
          font-size: 12px;
          color: #838DA0;
          border-radius: 0 0 8px 8px;
          margin-top: 12px;
          justify-content: flex-end;
          padding-right: 12px;
          column-gap: 10px;
          padding-top: 10px;
          border-top: 1px solid #EFEFEF;
          .danger-level-view{
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            padding: 0 8px;
            font-size: 13px;
            border-radius: 12px 2px 2px 2px;
            background-color: #007A77;
          }

          .level-JD{
            background-color: #F0A716;
          }

          .level-YB{
            background-color: #007A77;
          }

          .level-ZD{
            background-color: #f53f3f;
          }
        }

        .danger-YB{
          background: rgba(0,122,119,8%);
        }
        .danger-ZD{
          background: rgba(247,186,30,8%);
        }
        .danger-JD{
          background: rgba(255,125,0,8%);
        }
      }

      .YB{
        background: linear-gradient(to bottom, #F4FFFF 0, #FFFFFF 100%);
        border-top: 1px solid #D6F8F7;
        border-bottom: 1px solid white;
        border-left: 1px solid #D9F8F7;
        border-right: 1px solid #D9F8F7;
      }
      .JD{
        background: linear-gradient(to bottom, #FFFDF6 0, #FFFFFF 100%);
        border-top: 1px solid #FFF4D7;
        border-bottom: 1px solid white;
        border-left: 1px solid #FFFAED;
        border-right: 1px solid #FFFAED;
      }
      .ZD{
        background: linear-gradient(to bottom, #FFFBF8 0, #FFFFFF 100%);
        border-top: 1px solid #FFE0D7;
        border-bottom: 1px solid white;
        border-left: 1px solid #FFF1ED;
        border-right: 1px solid #FFF1ED;
      }

    }

    .add-view{
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      right: 30px;
      bottom: 40px;
      flex-direction: column;
      .add-txt{
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        background: #007A77;
        color: white;
        border-radius: 50%;
      }
    }
  }
}

.popup-title-txt{
  font-size: 16px;
  color: #03081A;
  font-weight: bold;
  padding-left: 20px;
}

.form-view {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  padding: 0 10px;
  margin: 0 12px;
  margin-top: 20px;

  .form-cell-view {
    display: flex;
    height: 46px;
    align-items: center;
    font-size: 15px;
    color: #838DA0;
    border-top: 1px solid #EFEFEF;
  }
}

.bottom-btn-view{
  display: flex;
  justify-content: space-between;
  padding: 15px 20px 0 20px;
  border-top: 1px solid #ebedf0;
  column-gap: 10px;
}

.add-btn-view {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 0;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 15px;
  color: white;
  border-radius: 25px;
  margin-top: 20px;
  background-color: #007A77;
}

::v-deep .van-popup--center.van-popup--round {
  border-radius: 8px;
}

::v-deep .van-icon{
  line-height: 19px;
}

::v-deep .van-pull-refresh{
  overflow: auto;
}

::v-deep .van-dropdown-menu__bar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow:none;
}

::v-deep .van-dropdown-menu__item{
  justify-content: flex-start;
}

::v-deep .van-dropdown-menu__title{
  padding: 0 10px 0 0;
  color: white;
  font-size: 13px;
}

</style>
